<template>
    <div class="form-container">
        <!-- 문의자 정보 -->
        <div class="mt-30 mt-md-40">
            <div class="mb-10">
                <h3 class="tit tit--xs">문의자 정보</h3>
            </div>
            <div class="table-style table-style--default">
                <myinfo-modify-row label="이름">
                    <v-text-field v-model="form.meta.name" type="text" outlined persistent-placeholder hide-details class="w-100 mw-lg-500px" />
                </myinfo-modify-row>

                <myinfo-modify-row label="이메일">
                    <div class="mw-lg-500px">
                        <v-row class="row--x-small" align="center">
                            <v-col>
                                <v-text-field v-model="form.meta.email1" type="text" name="email1" outlined hide-details class="w-100"></v-text-field>
                            </v-col>
                            <v-col cols="auto">@</v-col>
                            <v-col>
                                <v-text-field v-model="form.meta.email2" type="text" name="email2" outlined hide-details class="w-100"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="auto">
                                <v-select v-model="form.meta.email3" :items="[{ text: '직접입력', value: null }, 'naver.com', 'daum.com', 'google.com']" @change="form.meta.email2 = form.meta.email3" outlined hide-details class="w-100 mw-sm-160px" />
                            </v-col>
                        </v-row>
                    </div>
                </myinfo-modify-row>

                <myinfo-modify-row label="제목">
                    <v-text-field v-model="form.subject" outlined persistent-placeholder hide-details class="w-100 mw-lg-500px" />
                </myinfo-modify-row>
                
                <myinfo-modify-row label="내용">
                    <v-textarea v-model="form.content" outlined persistent-placeholder hide-details />
                </myinfo-modify-row>
                
                <myinfo-modify-row label="연락처">
                    <div class="mw-lg-500px">
                        <v-row class="row--x-small" align="center">
                            <v-col>
                                <v-select v-model="form.meta.phone1" :items="['010', '011', '016', '017', '019']" outlined hide-details></v-select>
                            </v-col>
                            <v-col cols="auto">-</v-col>
                            <v-col>
                                <v-text-field v-model="form.meta.phone2" maxlength="4" outlined hide-details @input="form.meta.phone2 = form.meta.phone2.replace(/[^0-9]/g, '')" />
                            </v-col>
                            <v-col cols="auto">-</v-col>
                            <v-col>
                                <v-text-field v-model="form.meta.phone3" maxlength="4" outlined hide-details @input="form.meta.phone2 = form.meta.phone2.replace(/[^0-9]/g, '')" />
                            </v-col>
                        </v-row>
                    </div>
                </myinfo-modify-row>
            </div>
        </div>
        <!-- 문의자 정보 -->

        <!-- 하단버튼 -->
        <div class="mt-30 mt-md-50">
            <div class="v-btn--group v-btn--group--large">
                <v-btn x-large outlined color="grey-cb" class="w-100 mw-220px" @click="$router.go(-1)"><span class="primary--text">취소</span></v-btn>
                <v-btn x-large color="primary" class="w-100 mw-220px" @click="$emit('save')">문의하기</v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import MyinfoModifyRow from "@/components/client/ko/mypage/myinfo-modify-row.vue";

import api from "@/api";

export default {
    components: {
        MyinfoModifyRow,
    },
    props: {
        value: { type: Object, default: null },
    },
    data() {
        return {
            form: {
                subject: "",
                content: "",
                meta: {
                    name: "",
                    email1: "",
                    email2: "",
                    email3: "",
                    phone1: "",
                    phone2: "",
                    phone3: ""
                }
                
                // this.$props.value,
            }
        };
    },
    computed: {
        // _work() {
        //     return this.$route.query._work;
        // },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
        },
    },
    watch: {
        value() {
            this.board = this.value;
        },

        "form.writer.email"() {
            this.$emit("input", this.form);
        },
        "form.writer.email1"() {
            this.$set(this.form.writer, "email", this.form.writer.email1 && this.form.writer.email2 ? `${this.form.writer.email1}@${this.form.writer.email2}` : null);
        },
        "form.writer.email2"() {
            this.$set(this.form.writer, "email", this.form.writer.email1 && this.form.writer.email2 ? `${this.form.writer.email1}@${this.form.writer.email2}` : null);
        },

        "form.writer.phone"() {
            this.$emit("input", this.form);
        },
        "form.writer.phone1"() {
            this.$set(this?.form?.writer, "phone", this?.form.writer.phone1 && this?.form.writer.phone2 && this?.form.writer.phone3 ? `${this?.form.writer.phone1}-${this?.form.writer.phone2}-${this?.form.writer.phone3}` : null);
        },
        "form.writer.phone2"() {
            this.$set(this?.form.writer, "phone", this?.form.writer.phone1 && this?.form.writer.phone2 && this?.form.writer.phone3 ? `${this?.form.writer.phone1}-${this?.form.writer.phone2}-${this?.form.writer.phone3}` : null);
        },
        "form.writer.phone3"() {
            this.$set(this?.form.writer, "phone", this?.form.writer.phone1 && this?.form.writer.phone2 && this?.form.writer.phone3 ? `${this?.form.writer.phone1}-${this?.form.writer.phone2}-${this?.form.writer.phone3}` : null);
        },

        "form.meta.email"() {
            this.$emit("input", this.form);
        },
        "form.meta.email1"() {
            this.$set(this?.form?.meta, "email", this?.form?.meta.email1 && this?.form?.meta.email2 ? `${this?.form?.meta.email1}@${this?.form?.meta.email2}` : null);
        },
        "form.meta.email2"() {
            this.$set(this?.form?.meta, "email", this?.form?.meta.email1 && this?.form?.meta.email2 ? `${this?.form?.meta.email1}@${this?.form?.meta.email2}` : null);
        },

        "form.meta.phone"() {
            this.$emit("input", this.form);
        },
        "form.meta.phone1"() {
            this.$set(this?.form?.meta, "phone", this?.form?.meta.phone1 && this?.form?.meta.phone2 && this?.form?.meta.phone3 ? `${this?.form?.meta.phone1}-${this?.form?.meta.phone2}-${this?.form?.meta.phone3}` : null);
        },
        "form.meta.phone2"() {
            this.$set(this?.form?.meta, "phone", this?.form?.meta.phone1 && this?.form?.meta.phone2 && this?.form?.meta.phone3 ? `${this?.form?.meta.phone1}-${this?.form?.meta.phone2}-${this?.form?.meta.phone3}` : null);
        },
        "form.meta.phone3"() {
            this.$set(this?.form?.meta, "phone", this?.form?.meta.phone1 && this?.form?.meta.phone2 && this?.form?.meta.phone3 ? `${this?.form?.meta.phone1}-${this?.form?.meta.phone2}-${this?.form?.meta.phone3}` : null);
        },
    },
};
</script>
